import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Table, Tr, Td, Thead, Tbody, TableCaption, Button, Flex, Spacer, Box, Collapse, useDisclosure,
    Tabs, TabList, TabPanels, Tab, TabPanel, Text, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Tooltip, Stack, Card, CardBody,
    Heading, CardFooter, Image, Avatar, Wrap, WrapItem, NumberInput, NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import Map from "./map";
import all_courts from '../data/courts.json';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import football_icon from '../soccer_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import boardgames_icon from "../chess2.png"
import player_avatar1 from "../player-avatar1.png"
import MessageBoard from "./messageBoard";
import { useRef } from 'react';
import Filter from 'bad-words';
import mapStyle from '../styles/mapStyle3.json';
import { MdGpsFixed } from "react-icons/md";
import { GiTennisCourt } from "react-icons/gi";
import { MdMail } from "react-icons/md";
import { IoMdRemoveCircle } from "react-icons/io";
import PlayersTab from "./playersTab";

// const locations = all_courts.map((x) => x.name);
// const locations =
//     ["NUS Multipurpose Hall, Court 1", "NUS Multipurpose Hall, Court 2",
//         "NUS Tennis Court 1", "NUS Tennis Court 2",
//         "NUS Basketball Court 1", "NUS Basketball Court 2"];

const sports = ["basketball", "tennis", "pickleball", "soccer", "hockey", "volleyball"]
const cities = [
    { value: "hongkong", label: "Hong Kong" },
    { value: "singapore", label: "Singapore" },
    { value: "vancouver", label: "Vancouver" }
];
// const sports = [...new Set(all_courts.map((x) => x.sport))];

// const sportLocations = {};
// all_courts.forEach((court) => {
//     const sport = court.sport.toLowerCase();
//     if (!sportLocations[sport]) {
//         sportLocations[sport] = [];
//     }
//     sportLocations[sport].push(court.name);
// });

const libraries = ['places'];

export default function Edit(props) {
    const [form, setForm] = useState({
        name: "",
        location: "",
        // time: "",
        datetime: "",
        length: "",
        sport: "",
        no_of_players: "",
        max_no_of_players: "",
        tags: "",
        records: [],
        players: [],
        guests: [],
        comments: "",
        level: "",
        public_setting: "",
        hasMessageBoard: "",
        game_id: "",
        location_decided: "",
        enable_message_board: "",
        message_host: "",
        location_decided: "",
        city: "",
        price: ""
    });
    const params = useParams();
    const navigate = useNavigate();
    const [selectedLocation, setSelectedLocation] = useState()
    const [locationTimes, setLocationTimes] = useState([]);
    const [formChanged, setFormChanged] = useState(false);
    const [isLoggedin, setIsLoggedin] = useState(!!props.username);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
        libraries: libraries
    });
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    const [zoom, setZoom] = useState(13)
    const [selectedSport, setSelectedSport] = useState("basketball");
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [tennisCourtNames, setTennisCourtNames] = useState([]);
    const [bballCourtNames, setBballCourtNames] = useState([]);
    const [mapLocation, setMapLocation] = useState("");
    const [filtered_CombinedCourts, setFiltered_CombinedCourts] = useState([]);
    const { isOpen, onToggle } = useDisclosure();
    const [hasMessageBoard, setHasMessageBoard] = useState(false);
    const [enableMessageBoard, setEnableMessageBoard] = useState(false);
    const [openMessageBox, setOpenMessageBox] = useState(false);
    const [message, setMessage] = useState("");
    const [enableMessageHost, setEnableMessageHost] = useState(0);
    const [disableDefaultUI, setDisableDefaultUI] = useState(true);
    const [mapType, setMapType] = useState("satellite");
    const [locationDecided, setLocationDecided] = useState("yes");
    const [openGuestJoinBox, setOpenGuestJoinBox] = useState(false);
    const [guestName, setGuestName] = useState("");
    const [guestEmail, setGuestEmail] = useState("");
    const [userLocation, setUserLocation] = useState(null);
    const [myLocationMarker, setMyLocationMarker] = useState(null);
    const [closeEnough, setCloseEnough] = useState(false);
    const [userCheckedIn, setUserCheckedIn] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // for debug
    // if (!props.combinedCourts) {
    //     console.error("props.combinedCourts is undefined");
    //     return null; // or handle the error appropriately
    // }
    // if (!form.location) {
    //     console.error("form.location is undefined");
    //     return null; // or handle the error appropriately
    // }

    useEffect(() => {
        // This effect runs whenever refreshTrigger changes.
        // Place your refresh logic here, such as fetching data.
        setShouldRender(shouldRender + 1);
    }, [refreshTrigger]);

    // const [mapType, setMapType] = useState("roadmap");
    // const [messages, setMessages] = useState()

    // const [gameId, setGameID] = useState();
    // const location = useLocation();
    // const records = location.state.records;
    const records = props.records;
    const id = params.id
    // console.log("ID: ", id)
    // var game_id = "asd";
    const currentUrl = window.location.href;
    // console.log("currentURL:", currentUrl);

    const minDate = new Date(); // set the minimum date to today's date
    const maxDate = new Date(); // create a new Date object for the maximum date
    maxDate.setDate(maxDate.getDate() + 180); // set the maximum date to 3 weeks after today's date

    // console.log("PROPS from edit: ", props)
    // console.log("edit records: ", props.records)
    // console.log("location State edit: ", location.state)
    // console.log("handleJoinClick from Edit", props.handleJoinClick);
    const [shouldRender, setShouldRender] = useState(0);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        if (currentDate.getTime() > selectedDate.getTime()) {
            return false
        } else if (locationTimes.find(datetime => datetime.getTime() === selectedDate.getTime())) {
            return false
        }
        else {
            return true
        }
        // return currentDate.getTime() < selectedDate.getTime();
    };


    useEffect(() => {
        async function fetchData() {
            const id = params.id.toString();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/${params.id.toString()}`);

            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const record = await response.json();
            if (!record) {
                window.alert(`Record with id ${id} not found`);
                navigate("/");
                return;
            }

            setForm(record);
            // console.log("RECORD", record);
            // console.log("RECORD.game_id", record.game_id);
            // setGameID(form.game_id);
            // console.log("gameId", gameId);
            setSelectedSport(record.sport);
            setEnableMessageBoard(record.enable_message_board)
            setEnableMessageHost(record.message_host)
            // console.log("record.location", record.location)
            handleLocationChange(record.location);
            setLocationDecided(record.location_decided);
            return record;
        }
        fetchData();
        return;
    }, [params.id, navigate]);

    useEffect(() => {
        const updatedLocalLocationTimes = locationTimes.filter((x) => x !== form.datetime);
        setLocationTimes(updatedLocalLocationTimes);
    }, [form.datetime]);

    // These methods will update the state properties.
    function updateForm(value) {
        setFormChanged(true);
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }

    useEffect(() => {
        const temp_filtered_combinedCourts = props.combinedCourts.filter((obj) => obj.sport == props.selectedSport).sort((a, b) => a.name.localeCompare(b.name));
        setFiltered_CombinedCourts(temp_filtered_combinedCourts);
        // console.log("combinedCourts on Create:", temp_filtered_combinedCourts)
    },
        [props.selectedSport, props.combinedCourts])

    const handleLocationChange = (location) => {
        // setMapLocation(location);
        // handleLocationDecidedChange("yes")
        // console.log("mapLocation", location)
        // const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        // console.log("HANDLE LOCATION CHANGE", props.combinedCourts)
        const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        if (selectedCourt) {
            // console.log("selectedCourt:", selectedCourt)
            // console.log("coordinates:", selectedCourt.coordinates)
            setCenter({ lat: selectedCourt.coordinates[1], lng: selectedCourt.coordinates[0] })
            setZoom(17);
        } else {
            // console.log("Selected court not found:", location);
        }
    };


    useEffect(() => {
        if (props.combinedCourts.length === 0) {
            console.log("Combined courts data is not available yet.");
            return;
        }
        // console.log("Hi", form.location)
        // handleLocationChange(form.location)
        if (form.location) {
            handleLocationChange(form.location);
        } else {
            console.log("form.location is not set.");
        }
        setIsLoggedin(!!props.username)
        // setShouldRender(shouldRender + 1)
    }, [props.combinedCourts, props.username]);

    function setLocationNone() {
        return setForm((prev) => {
            return { ...prev, location: "" };
        });
    }

    if (props.debugMode) { console.log("CHECK: form.datetime: ", form.datetime) }

    async function onSubmit(e) {
        const result = window.confirm("Are you sure you want to update this game?");
        if (!result) {
            return;
        } else {
            e.preventDefault();
            let success;

            if (!form.name) {
                window.alert("Username is empty.");
                return;
            }

            if (!form.location || !form.datetime || !form.sport || !form.length) {
                window.alert("Invalid: please fill in the required fields on the form")
                return;
            }

            const max_no_of_players = +form.max_no_of_players
            // if (isNaN(max_no_of_players) || max_no_of_players > 100) {
            //     window.alert("Invalid: max number of players must be an integer value, and must be less than 100");
            //     return;
            // }

            if (isNaN(max_no_of_players) || !Number.isInteger(max_no_of_players) || max_no_of_players > 100) {
                window.alert("Invalid: max number of players must be an integer value, and must not be greater than 100");
                return;
            }

            if (form.no_of_players > form.max_no_of_players) {
                window.alert("Invalid: max number of players must be at least " + form.no_of_players);
                return;
            }

            const filteredArray = props.combinedCourts.filter((obj) => form.location == obj.id);
            const location_name = filteredArray.length > 0 ? filteredArray[0].name : "To Be Decided";
            // console.log("location_name:", location_name);

            const editedPerson = {
                name: form.name,
                location: form.location,
                location_name: location_name,
                // time: form.time,
                datetime: form.datetime,
                length: form.length,
                sport: form.sport,
                no_of_players: form.no_of_players,
                max_no_of_players: form.max_no_of_players,
                tags: form.tags,
                comments: form.comments,
                level: form.level,
                game_id: form.game_id,
                public_setting: form.public_setting,
                enable_message_board: form.enable_message_board,
                message_host: form.message_host,
                location_decided: form.location_decided,
                city: form.city,
                price: form.price
            };
            // console.log("CHECKK")
            // This will send a post request to update the data in the database.
            await fetch(`${process.env.REACT_APP_API_URL}/update/${params.id}`, {
                method: "POST",
                body: JSON.stringify(editedPerson),
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    // console.log("Response status:", response.status);
                    if (response.status === 409) {
                        window.alert("Court is already full at requested time. Please choose another time or court.");
                    }
                    success = false
                    return response.json();
                })
                .then(data => {
                    // console.log("Response data:", data);
                })
                .catch(error => {
                    window.alert(error);
                    return;
                });
            // console.log("success? ", success)
            // if (!success) {
            //     navigate(`/edit/${params.id}`);
            // }
            setFormChanged(false);
            window.alert("Game updated successfully.")
            // setShouldRender(shouldRender + 1);
            // navigate("/");
        }
    }

    const inputRef = useRef(null);

    // This method will delete a record
    async function deleteRecord(id) {
        const result = window.confirm("Are you sure you want to delete this game?");
        if (!result) {
            return;
        } else {
            // User clicked cancel
            // Do not proceed with delete

            await fetch(`${process.env.REACT_APP_API_URL}/${id}`, {
                method: "DELETE"
            });

            // const newRecords = records.filter((el) => el._id !== id);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
            window.alert(`Game ${params.id} has been deleted.`);
            navigate("/")

            //   const records = await response.json();
            //   setRecords(records);
            // setRecords(newRecords);
        }
    }
    function handleBackClick() {
        // navigate(-1);
        navigate("/");
    }

    async function joinGame() {
        // console.log("gameId:", gameId);
        const result = window.confirm("Are you sure you want to join game " + form.game_id + "?");
        if (!result) {
            return;
        } else {
            await fetch(`${process.env.REACT_APP_API_URL}/${params.id}`, {
                method: "POST",
                // body: JSON.stringify({ username: props.username, game_id: gameId }),
                body: JSON.stringify({ username: props.username, game_id: form.game_id }),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
            setShouldRender(shouldRender + 1); // update state variable
        }
    }

    async function leaveGame() {
        const result = window.confirm("Are you sure you want to leave game " + form.game_id + "?");
        // console.log("gameId in leaveGame", gameId)
        if (!result) {
            return;
        } else {
            await fetch(`${process.env.REACT_APP_API_URL}/remove_game/${id}`, {
                method: "POST",
                // body: JSON.stringify({ username: props.username, game_id: gameId }),
                body: JSON.stringify({ username: props.username, game_id: form.game_id }),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
            setShouldRender(shouldRender + 1);
        }
    }

    // useEffect(() => {
    //     setShouldRender(shouldRender + 1)
    // },
    //     [form.players, props.username])


    const handleMessageHost = () => {
        setOpenMessageBox(true);
    };

    const handleClose = () => {
        setOpenMessageBox(false);
    };

    const handleSendMessage = () => {
        // Handle message submission logic here
        // console.log("Sending message:", message);
        if (!message) {
            window.alert("Message is empty.")
            return;
        }
        // Create a new instance of the profanity filter
        const filter = new Filter();

        if (filter.isProfane(message)) {
            window.alert("Message contains inappropriate content.");
            return;
        }

        try {
            fetch(`${process.env.REACT_APP_API_URL}/message_host`, {
                method: "POST",
                body: JSON.stringify({
                    sender_name: props.username,
                    game_id: form.game_id,
                    host: form.name,
                    message: message,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        console.log("Sent message:", message);
                        window.alert("Message sent successfully.");
                    } else if (response.status === 400) {
                        return response.json().then(data => {
                            const errorMessage = data.error;
                            window.alert("Error: " + errorMessage);
                        });
                    }
                })
        } catch (error) {
            console.log("Error sending message:", error);
            window.alert("Error sending message")
        }
        setOpenMessageBox(false);
        setMessage("");
        inputRef.current.blur();

    };

    const handleOpenGuestJoin = () => {
        setOpenGuestJoinBox(true);
    };

    const handleCloseGuestJoin = () => {
        setOpenGuestJoinBox(false);
    };

    const handleGuestJoinSubmit = async () => {
        // console.log("handleGuestJoinSubmit: Guest Name:", guestName, form.game_id, form.name, guestEmail);
        if (!guestName) {
            window.alert("Guest name cannot be empty.")
            return;
        }
        // Create a new instance of the profanity filter
        const filter = new Filter();

        if (filter.isProfane(message)) {
            window.alert("Name contains inappropriate content.");
            return;
        }
        try {
            fetch(`${process.env.REACT_APP_API_URL}/guest_join`, {
                method: "POST",
                body: JSON.stringify({
                    guest_name: guestName,
                    game_id: form.game_id,
                    host: form.name,
                    guest_email: guestEmail
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        console.log("Guest", guestName, "successfully joined.");
                        window.alert("Guest successfully joined.");
                    } else if (response.status === 400) {
                        return response.json().then(data => {
                            const errorMessage = data.error;
                            window.alert("Error: " + errorMessage);
                        });
                    }
                })

            // const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);
            // if (!response.ok) {
            //     const message = `An error occurred: ${response.statusText}`;
            //     window.alert(message);
            //     return;
            // }
            // setShouldRender(shouldRender + 1); // update state variable
        } catch (error) {
            console.log("Error sending message:", error);
            window.alert("Error sending message");
        }
        setOpenGuestJoinBox(false);
        setGuestName("");
        setShouldRender(shouldRender + 1); // update state variable
        inputRef.current.blur();
    };


    function handleJoinClick(e) {
        e.preventDefault();
        if (!props.username) {
            // history.push('/login');
            navigate(`/login`);
        }
        else {
            joinGame();
        }
    }

    function handleLeaveClick(e) {
        e.preventDefault();
        leaveGame();
    }

    useEffect(() => {
        // do something whenever joinGameCount changes
        // console.log("SHOULD RENDER")
        async function fetchData() {
            const id = params.id.toString();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/${params.id.toString()}`);

            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const record = await response.json();
            if (!record) {
                window.alert(`Record with id ${id} not found`);
                navigate("/");
                return;
            }

            setForm(record);
            // console.log("RECORD123", record);
            // console.log("RECORD.game_id", record.game_id);
            handleLocationChange(record.location);
            // setGameID(form.game_id);
            // console.log("gameId", gameId);
            return record;
        }
        fetchData();
        return;
    }, [shouldRender]);

    function copyURLToClipboard() {
        navigator.clipboard.writeText(window.location.href);
    }

    let courtsList;
    let icon;
    switch (selectedSport) {
        case "basketball":
            courtsList = props.bballCourts;
            icon = {
                url: basketball_icon, scaledSize: { width: 30, height: 30 }
            };
            break;
        case "tennis":
            courtsList = props.tennisCourts;
            icon = { url: tennis_icon, scaledSize: { width: 30, height: 30 } };
            break;
        case "football":
            courtsList = props.soccerCourts;
            icon = { url: football_icon, scaledSize: { width: 30, height: 30 } };
            break;
        case "board games":
            courtsList = props.soccerCourts;
            icon = { url: boardgames_icon, scaledSize: { width: 50, height: 50 } };
            break;
        default:
            courtsList = null; // Default value if no case matches
    }

    const toggleMapType = () => {
        if (mapType === "satellite") {
            setMapType("roadmap");
            setDisableDefaultUI(false);
        } else {
            setMapType("satellite");
            setDisableDefaultUI(true);
        }
    };

    // console.log("combinedCourts", props.combinedCourts);
    // const new_markers = props.combinedCourts.filter((obj) => obj.sport == selectedSport).map(court => {
    // console.log("form.location", form.location)
    // console.log("props.combinedCourts", props.combinedCourts)
    // const new_markers = props.combinedCourts.filter((obj) => obj.id == form.location).map(court => {
    const new_markers = props.combinedCourts.filter((obj) => obj.id == form.location).map(court => {
        // const filtered_combinedCourts_list = props.combinedCourts.filter((obj) => obj.sport == selectedSport)
        const markerPosition = { lat: court.coordinates[1], lng: court.coordinates[0] };
        return (
            <Marker
                key={court.id}
                position={markerPosition}
                onClick={() => setSelectedCourt(court)}
                icon={icon}
            >
                {selectedCourt === court && (
                    <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                        <div>
                            {/* <h3>Combined Courts Info</h3> */}
                            <h6><b>{court.name}</b></h6>
                            {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                            <p>Address: {court.address} <br /> (<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                            <p>Sport: {court.sport}</p>
                            {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                            {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                            {/* <p>Status: </p> */}
                            <p style={{ fontSize: '6px' }}>Source: {court.source}</p>
                            <Button
                                colorScheme='teal'
                                label="Change Map Style"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={toggleMapType}
                            >
                                Change Map Style
                            </Button>
                        </div>
                    </InfoWindow>)}
            </Marker>)
    })

    useEffect(() => {
        const updatedCourtNames = props.tennisCourts.map(court => court.properties.NAME_EN);
        setTennisCourtNames(updatedCourtNames.sort());
        // console.log("updatedCourtNames:", updatedCourtNames);
    }, [props.tennisCourts])

    useEffect(() => {
        const updatedCourtNames = props.bballCourts.map(court => court.properties.NAME_EN);
        setBballCourtNames(updatedCourtNames.sort());
        // console.log("updatedBballCourtNames:", updatedCourtNames);
    }, [props.bballCourts])

    useEffect(() => {
        const updatedCourtNames = props.bballCourts.map(court => court.properties.NAME_EN);

        // console.log("props.basketball_google:", props.basketball_google);
        const updatedCourtNames_google = props.basketball_google.map(court => court.name);
        // console.log("google courts:", updatedCourtNames_google);
        // console.log("props.db_courts:", props.db_courts);
        // const updatedCourtNames_db = props.db_courts.filter(court => court.active === true && court.sport === "basketball")
        //     .map(court => court.name);
        const updatedCourtNames_db = props.db_courts.filter(court => court.sport == "basketball" && court.active == true)
            .map(court => court.name);
        // console.log("db courts:", updatedCourtNames_db);

        const mergedCourtNames = [...updatedCourtNames, ...updatedCourtNames_google, ...updatedCourtNames_db];
        setBballCourtNames(mergedCourtNames.sort());
        // console.log("mergedCourtNames:", mergedCourtNames);
    }, [props.bballCourts, props.db_courts, props.basketball_google])

    // console.log("combined courts list on edit:", props.combinedCourts.filter((obj) => obj.sport == selectedSport).sort((a, b) => a.name.localeCompare(b.name)))

    const handleMessageBoardUpdate = (value) => {
        setHasMessageBoard(value);
    };

    function handleLocationDecidedChange(value) {
        setLocationDecided(value);
        if (value === 'no') {
            setSelectedLocation('TBD');
            updateForm({ location: 'TBD' });
            updateForm({ location_decided: value });
            // updateForm({ location: 'TBD' });
        }
        if (value === 'yes') {
            // setSelectedLocation(null);
            // updateForm({ location: null });
            updateForm({ location_decided: value });
        }
        console.log("locationDecided changed")
    }

    // console.log("EDIT")
    // console.log("isLoggedin", isLoggedin);
    // console.log("form", form)
    // console.log("form.players", form.players)
    // console.log("form.players.includes(props.username)", form.players.includes(props.username))
    // console.log("props.username", props.username)

    const message_host_disabled = !((isLoggedin && form && form.players && form.players.includes(props.username) && (props.username !== form.name) && enableMessageHost == 2)
        || ((isLoggedin && form && form.players && (props.username !== form.name) && enableMessageHost == 1)))

    // useEffect(() => {
    //     // Check if Geolocation API is available in the browser
    //     if (navigator.geolocation) {
    //         // Request user's location
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 const { latitude, longitude } = position.coords;
    //                 setUserLocation({ latitude, longitude });
    //             },
    //             error => {
    //                 console.error('Error getting user location:', error);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation API is not supported in this browser.');
    //     }
    // }, []);


    const handleMyLocation = () => {
        if (navigator.geolocation) {
            // Request user's location
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                    setCenter({ lat: latitude, lng: longitude });
                    setZoom(17);
                    const myLocationMarker = (
                        <Marker
                            key={1}
                            position={{ lat: latitude, lng: longitude }}
                        //   onClick={() => setSelectedCourt(court)}
                        //   icon={icon}
                        />
                    );
                    setMyLocationMarker(myLocationMarker);
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation API is not supported in this browser.');
        }

    }

    const handleCourtLocation = () => {
        const location = form.location;
        // console.log("location", form.location)
        // setCenter({});
        const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        if (selectedCourt) {
            // console.log("selectedCourt:", selectedCourt)
            // console.log("coordinates:", selectedCourt.coordinates)
            setCenter({ lat: selectedCourt.coordinates[1], lng: selectedCourt.coordinates[0] })
            setZoom(17);
        }
    }


    const handleCheckIn = (e, court_id) => {
        if (closeEnough) {
            console.log("Check-in successful");
            window.alert("Check-in successful! :D");
            setUserCheckedIn(true);
        } else {
            console.log("Distance is too far for check-in");
            window.alert("Distance is too far for check-in. :(");
        }
    };

    const calculateDistance = (coord1, coord2) => {
        const earthRadius = 6371; // Radius of the Earth in kilometers
        const latDiff = toRadians(coord2.latitude - coord1.latitude);
        const lonDiff = toRadians(coord2.longitude - coord1.longitude);
        const a =
            Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
            Math.cos(toRadians(coord1.latitude)) *
            Math.cos(toRadians(coord2.latitude)) *
            Math.sin(lonDiff / 2) *
            Math.sin(lonDiff / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c * 1000; // Convert distance to meters
        return distance;
    };

    const toRadians = (angle) => {
        return (angle * Math.PI) / 180;
    };

    useEffect(() => {
        if (userLocation && form.location) {
            const selectedCourt = props.combinedCourts.find((court) => court.id === form.location);

            if (selectedCourt) {
                const userCoordinates = {
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                };

                const courtCoordinates = {
                    latitude: selectedCourt.coordinates[1],
                    longitude: selectedCourt.coordinates[0],
                };

                const distance = calculateDistance(userCoordinates, courtCoordinates);

                if (distance < 300) {
                    console.log("User is close enough for check-in");
                    setCloseEnough(true);
                } else {
                    console.log("Distance is too far for check-in");
                    setCloseEnough(false);
                }
            }
        }
    }, [userLocation, form.location, props.combinedCourts]);

    // In the Edit component
    const updatePlayers = (newPlayers) => {
        setForm((prevForm) => ({
            ...prevForm,
            players: newPlayers,
        }));
    };

    if (!isLoaded) return <div>Loading...</div>;

    // This following section will display the form that takes input from the user to update the data.
    return (
        <Flex direction={{ base: 'column-reverse', md: 'row' }}
            marginX={{ base: '0', md: '0' }}
            marginY={{ base: '0', md: '4' }}
        >
            <Spacer />
            <Box h="100%" w={{ base: '90%', md: '40%' }}
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '4', md: '0' }}
            >
                {props.username === form.name ? <h3><b>Update Game Details (#{form.game_id})</b></h3>
                    : <h3><b>View Game Details (#{form.game_id})</b></h3>}
                <br />
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Host Name: </label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            // value={`${form.name.substring(0, 3)}*****`}
                            value={`${form.name}`}
                            onChange={(e) => updateForm({ name: e.target.value })}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="sport">{form.sport == "board games" ? "Activity" : "Sport"}</label>
                        <select
                            type="text"
                            className="form-control"
                            id="sport"
                            value={selectedSport}
                            onChange={(e) => {
                                updateForm({ sport: e.target.value });
                                // handleLocationChangeSport({ sport: e.target.value });
                                setLocationNone()
                            }}
                            // disabled={props.username === form.name ? "" : "restricted"}
                            disabled
                        // required
                        >
                            <option value="" hidden selected>
                                {selectedSport.charAt(0).toUpperCase() + selectedSport.slice(1) || "Select Sport"}
                            </option>
                            {sports.map((sport) => (
                                <option key={sport} value={sport}>
                                    {sport.charAt(0).toUpperCase() + sport.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="city">Which City?</label>
                        <select
                            className="form-control"
                            id="city"
                            value={form.city}
                            onChange={(e) => updateForm({ city: e.target.value })}
                            required
                            disabled
                        >
                            {/* <option value="">Which City?</option> */}
                            {cities.map((city) => (
                                <option key={city.value} value={city.value}>
                                    {city.label}
                                </option>
                            ))}

                        </select>
                    </div>

                    {props.username === form.name && (
                        <div className="form-group">
                            <label htmlFor="location_decided">Do you know where you want to play?</label>
                            <select
                                type="text"
                                className="form-control"
                                id="location_decided"
                                value={form.location_decided}
                                onChange={(e) => {
                                    updateForm({ location_decided: e.target.value });
                                    handleLocationDecidedChange(e.target.value);
                                }}
                                required
                                disabled={props.username === form.name ? "" : "restricted"}
                            >
                                <option key="yes" value="yes"> Yes</option>
                                <option key="no" value="no"> Not sure yet</option>
                            </select>
                        </div>
                    )}

                    {locationDecided === "yes" && (

                        <div className="form-group">
                            <label htmlFor="location">Location</label>
                            <select
                                type="text"
                                className="form-control"
                                id="location"
                                value={form.location}
                                onChange={(e) => {
                                    updateForm({ location: e.target.value });
                                    handleLocationChange(e.target.value)
                                    handleLocationDecidedChange("yes")
                                }}
                                required
                                disabled={props.username === form.name ? "" : "restricted"}
                            >
                                <option value="" hidden selected>
                                    Select Location
                                </option>
                                {/* {props.combinedCourts.map(court =>
                                <option key={court.id} value={court.id}>
                                    {court.name}
                                </option>)
                            } */}
                                {/* {props.combinedCourts.filter((obj) => obj.sport == selectedSport).sort((a, b) => a.name.localeCompare(b.name)).map(court =>
                                    <option key={court.id} value={court.id}>
                                        {court.name}
                                    </option>)
                                } */}

                                {/* Adding OTHER as an option */}
                                {[...props.combinedCourts.filter((obj) => obj.sport == selectedSport).sort((a, b) => a.name.localeCompare(b.name)), { id: 'other', name: 'Other' }].map(court =>
                                    <option key={court.id} value={court.id}>
                                        {court.name}
                                    </option>
                                )}
                                {/* {filtered_CombinedCourts.map(court =>
                                <option key={court.id} value={court.id}>
                                    {court.name}
                                </option>)
                            } */}

                            </select>
                        </div>
                    )}

                    <div style={{ fontSize: '12px' }}><b>Address:</b> {props.combinedCourts.filter(court => form.location == court.id).length > 0 ? props.combinedCourts.filter(court => form.location == court.id)[0].address : ""}</div>

                    {/* {court.properties.ADDRESS_EN} */}

                    <div className="form-group">
                        <label htmlFor="datetime">Date and Start Time</label>
                        <DatePicker
                            selected={form.datetime ? new Date(form.datetime) : new Date()}
                            onChange={(datetime) => updateForm({ datetime })}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            minDate={minDate}
                            maxDate={maxDate}
                            filterTime={filterPassedTime}
                            // dateFormat="yyyy-MM-dd HH:mm"
                            className="form-control"
                            disabled={props.username === form.name ? "" : "restricted"}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="length">Session Length</label>
                        <select
                            type="text"
                            className="form-control"
                            id="length"
                            value={form.length}
                            onChange={(e) => {
                                updateForm({ length: e.target.value });
                            }}
                            disabled={props.username === form.name ? "" : "restricted"}
                            required
                        >
                            <option value="" hidden selected>
                                Session Length
                            </option>
                            <option key="30min" value="30"> 30 mins</option>
                            <option key="1hour" value="60"> 1 hour</option>
                            <option key="1.5hour" value="90"> 1.5 hour</option>
                            <option key="2hour" value="120"> 2 hours</option>
                            <option key="2.5hour" value="150"> 2.5 hours</option>
                            <option key="3hour" value="180"> 3 hours</option>
                            <option key="3hour+" value="181"> More than 3 hours</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="no_of_players">No. of Players</label>
                        <input
                            type="number"
                            className="form-control"
                            id="no_of_players"
                            value={form.no_of_players}
                            onChange={(e) => updateForm({ no_of_players: e.target.value })}
                            // disabled={props.username === form.name ? "" : "restricted"}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="max_no_of_players">Max No. of Players</label>
                        <input
                            type="number"
                            className="form-control"
                            id="max_no_of_players"
                            value={form.max_no_of_players}
                            onChange={(e) => updateForm({ max_no_of_players: e.target.value })}
                            disabled={props.username === form.name ? "" : "restricted"}
                            required
                        />
                    </div>

                    {/* <div className="form-group">
                        <label htmlFor="no_of_players">Price?</label>
                        <input
                            type="text"
                            className="form-control"
                            id="price"
                            value={form.price}
                            onChange={(e) => updateForm({ price: e.target.value })}
                            // required
                            disabled={props.username === form.name ? "" : "restricted"}
                        />
                    </div> */}
                    <label htmlFor="no_of_players">Price per player <span style={{ fontSize: "9px" }}>(Let's keep it free by default :) )</span>&nbsp;&nbsp;</label>
                    <Stack direction="row" align="center">
                        <Text>$</Text>
                        <NumberInput
                            value={form.price}
                            onChange={(valueString) => updateForm({ price: parseInt(valueString, 10) })}
                            min={0}
                            max={50}
                            step={1}
                            isDisabled={props.username !== form.name}
                        >
                            <NumberInputField id="price" className="form-control" />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Text>HKD</Text>
                    </Stack>

                    <div className="form-group">
                        <label htmlFor="public_setting">Open to Public? <span style={{ fontSize: "9px" }}>(private games will not be displayed on the main page, only accessible via URL)</span>&nbsp;&nbsp;</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="public"
                                id="public"
                                value="public"
                                checked={form.public_setting === "public"}
                                disabled={props.username === form.name ? "" : "restricted"}
                                onChange={(e) => updateForm({ public_setting: e.target.value })}
                            />
                            <label htmlFor="public_setting" className="form-check-label">Public</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="private"
                                id="private"
                                value="private"
                                checked={form.public_setting === "private"}
                                disabled={props.username === form.name ? "" : "restricted"}
                                onChange={(e) => updateForm({ public_setting: e.target.value })}
                            />
                            <label htmlFor="public_setting" className="form-check-label">Private</label>
                        </div>
                    </div>

                    {form.name == props.username && (
                        <div className="form-group">
                            <label htmlFor="enable_message_board">Message Board? <span style={{ fontSize: "9px" }}>(Premium feature: note all participants can see and send messages)</span>&nbsp;&nbsp;</label>
                            <br />
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="enable_message_board"
                                    id="yes"
                                    value={true}
                                    checked={form.enable_message_board === true}
                                    disabled={props.username === form.name ? "" : "restricted"}
                                    onChange={(e) => updateForm({ enable_message_board: e.target.value === 'true' })}
                                />
                                <label htmlFor="enable_message_boarding" className="form-check-label">Enable</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="enable_message_board"
                                    id="no"
                                    value={false}
                                    checked={form.enable_message_board === false}
                                    disabled={props.username === form.name ? "" : "restricted"}
                                    onChange={(e) => updateForm({ enable_message_board: e.target.value === 'true' })}
                                />
                                <label htmlFor="enable_message_board" className="form-check-label">Disable</label>
                            </div>
                        </div>
                    )}

                    {form.name == props.username && (
                        <div className="form-group">
                            <label htmlFor="message_host">Message Host Settings <span style={{ fontSize: "9px" }}>(Determine who can message you)</span>&nbsp;&nbsp;</label>
                            <select
                                type="text"
                                className="form-control"
                                id="message_host"
                                value={form.message_host}
                                onChange={(e) => {
                                    updateForm({ message_host: e.target.value });
                                }}
                                disabled={props.username === form.name ? "" : "restricted"}
                            >
                                <option key="Disable" value={0}> Disable</option>
                                <option key="Enabled for all users" value={1}> Enable for all users</option>
                                <option key="Enable for game participants only" value={2}> Enable for game participants only</option>
                            </select>
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="tags">Tags</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tags"
                            value={form.tags}
                            onChange={(e) => updateForm({ tags: e.target.value })}
                            disabled={props.username === form.name ? "" : "restricted"}
                            maxLength={30}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="skill">Skill Level:&nbsp;&nbsp;</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelBeginner"
                                value="Beginner"
                                checked={form.level === "Beginner"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                                disabled={props.username === form.name ? "" : "restricted"}
                            />
                            <label htmlFor="levelBeginner" className="form-check-label">Beginner</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelIntermediate"
                                value="Intermediate"
                                checked={form.level === "Intermediate"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                                disabled={props.username === form.name ? "" : "restricted"}
                            />
                            <label htmlFor="levelIntermediate" className="form-check-label">Intermediate</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelAdvanced"
                                value="Advanced"
                                checked={form.level === "Advanced"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                                disabled={props.username === form.name ? "" : "restricted"}
                            />
                            <label htmlFor="levelAdvanced" className="form-check-label">Advanced</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="NA"
                                value="NA"
                                checked={form.level === "NA"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                                disabled={props.username === form.name ? "" : "restricted"}
                            />
                            <label htmlFor="levelAdvanced" className="form-check-label">N/A</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="comments">Comments</label>
                        <input
                            type="textarea"
                            className="form-control"
                            id="comments"
                            value={form.comments}
                            onChange={(e) => updateForm({ comments: e.target.value })}
                            disabled={props.username === form.name ? "" : "restricted"}
                            maxLength={150}
                        />
                    </div>

                    <br />
                    {/* <div>Participants: {JSON.stringify(form.players)}</div> */}
                    {/* <div><b>Participants:</b> {form.players && form.players.length > 0 ? form.players.join(", ") : "None"}</div> */}
                    <div>
                        <b>Registered Players:</b>{" "}
                        {/* {form.players && form.players.length > 0
                            ? form.players.map(player => `${player.substring(0, 3)}*****`).join(", ")
                            : "None"} */}
                        {form.players && form.players.length > 0
                            ? form.players.map(player => `${player}`).join(", ")
                            : "None"}

                    </div>
                    <div>
                        <b>Guest Players:</b>{" "}
                        {form.guests && form.guests.length > 0
                            ? form.guests.map(guest => guest.guest_name).join(", ")
                            : "None"}
                    </div>

                    <br />

                    <div style={{ display: "flex" }}>
                        <Button
                            leftIcon={<ArrowBackIcon />}
                            variant='outline'
                            colorScheme='green'
                            onClick={handleBackClick}
                            mr={2}
                            whiteSpace="normal" // Enable text wrapping
                            overflowWrap="break-word" // Allow words to break within the button
                            fontSize="sm"
                        >
                            Back
                        </Button>

                        {/* <div className="form-group">
                            <input
                                type="submit"
                                value="Submit Changes"
                                // className="btn btn-primary"
                                className="btn btn-primary"
                                // disabled={(props.username && formChanged) === form.name ? "" : "restricted"}
                                disabled={(!props.username === form.name) || !formChanged}
                            // disabled={!formChanged}
                            />
                        </div> */}
                        {form.name === props.username ? (
                            <Button
                                onClick={onSubmit}
                                colorScheme={formChanged ? "blue" : "gray"}
                                isDisabled={(!props.username === form.name) || !formChanged}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                                fontSize="sm"
                            >
                                {"Submit Changes"}
                            </Button>
                        ) : null}

                        {form.name === props.username ? (
                            <Button
                                colorScheme='red'
                                onClick={() => {
                                    deleteRecord(params.id);
                                }}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                                fontSize="sm"
                            // ml={2}
                            >
                                Delete Game
                            </Button>
                        ) : null}

                        {/* {isLoggedin && form && form.players && !form.players.includes(props.username) && (
                            <Button
                                // variant='outline'
                                colorScheme='teal'
                                onClick={(e) => {
                                    handleJoinClick(e);
                                }}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                            >
                                Join
                            </Button>)
                        } */}

                        {/* {form && form.players && !form.players.includes(props.username) && (
                            <Button
                                // variant='outline'
                                // colorScheme='teal'
                                colorScheme={props.username ? "teal" : "yellow"}
                                variant={props.username ? "solid" : "outline"}
                                onClick={(e) => {
                                    handleJoinClick(e);
                                }}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                            >

                                {props.username ? "Join" : "Register or Login to Join"}
                            </Button>)
                        } */}

                        {form && form.players && !form.players.includes(props.username) && (
                            <Button
                                // variant='outline'
                                // colorScheme='teal'
                                colorScheme={props.username ? "teal" : "yellow"}
                                variant={props.username ? "solid" : "outline"}
                                onClick={(e) => {
                                    handleJoinClick(e);
                                }}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                                isDisabled={!(form && form.players && !form.players.includes(props.username) && (form.no_of_players < form.max_no_of_players))}
                                fontSize="sm"
                            >
                                {/* Join */}
                                {props.username ? "Join" : "Login to Join"} {(form.no_of_players >= form.max_no_of_players) ? "(Full)" : ""}
                            </Button>
                        )}

                        {((props.username == form.name) || !props.username) && (
                            <Tooltip
                                label="Add unregistered guests by name."
                            >
                                <Button
                                    // variant='outline'
                                    // colorScheme='teal'
                                    colorScheme={props.username ? "teal" : "yellow"}
                                    variant={props.username ? "solid" : "outline"}
                                    onClick={handleOpenGuestJoin}
                                    mr={2}
                                    whiteSpace="normal" // Enable text wrapping
                                    overflowWrap="break-word" // Allow words to break within the button
                                    isDisabled={!(form && form.players && (form.no_of_players < form.max_no_of_players))}
                                    fontSize="sm"
                                >
                                    {/* Join */}
                                    {props.username == form.name ? "Add Guests" : "Join as Guest"} {(form.no_of_players >= form.max_no_of_players) ? "(Full)" : ""}
                                </Button>
                            </Tooltip>
                        )}



                        {isLoggedin && form && form.players && form.players.includes(props.username) && (
                            <Button
                                // variant='outline'
                                // colorScheme='red'
                                bg="gray.700" // Dark grey background color
                                color="white" // White font color
                                onClick={(e) => {
                                    handleLeaveClick(e);
                                }}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                                fontSize="sm"
                            >
                                Leave
                            </Button>)
                        }


                        {((props.username !== form.name) && props.username) && (
                            <Button
                                // variant='outline'
                                colorScheme='blue'
                                onClick={handleMessageHost}
                                mr={2}
                                whiteSpace="normal" // Enable text wrapping
                                overflowWrap="break-word" // Allow words to break within the button
                                // isDisabled={!((isLoggedin && form && form.players && form.players.includes(props.username) && (props.username !== form.name) && enableMessageHost == 2)
                                //     || ((isLoggedin && form && form.players && (props.username !== form.name) && enableMessageHost == 1)))}
                                isDisabled={message_host_disabled}
                                fontSize="sm"
                            >
                                Message Host {message_host_disabled ? "(Disabled)" : ""}
                            </Button>
                        )}

                        <Modal isOpen={openMessageBox} onClose={handleClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Enter Message to Host</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel htmlFor="message">Message:</FormLabel>
                                        <Input
                                            type="text"
                                            id="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            ref={inputRef} // Add the ref to the input element
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleSendMessage();
                                                }
                                            }}
                                            maxLength={80}
                                        />
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleSendMessage}>
                                        Send
                                    </Button>
                                    <Button variant="ghost" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <Modal isOpen={openGuestJoinBox} onClose={handleCloseGuestJoin}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>{props.username == form.name ? "Guest Player's Name" : "What's your name?"}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel htmlFor="guestName">Name:</FormLabel>
                                        <Input
                                            type="text"
                                            id="guestName"
                                            value={guestName}
                                            onChange={(e) => setGuestName(e.target.value)}
                                            ref={inputRef} // Add the ref to the input element
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleGuestJoinSubmit();
                                                }
                                            }}
                                            maxLength={20}
                                        />
                                        <FormLabel htmlFor="guestEmail">Email (optional):</FormLabel>
                                        <Input
                                            type="text"
                                            id="guestEmail"
                                            value={guestEmail}
                                            onChange={(e) => setGuestEmail(e.target.value)}
                                            ref={inputRef} // Add the ref to the input element
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleGuestJoinSubmit();
                                                }
                                            }}
                                            maxLength={30}
                                        />
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleGuestJoinSubmit}>
                                        Join
                                    </Button>
                                    <Button variant="ghost" onClick={handleCloseGuestJoin}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        {isLoggedin && form && form.players && form.players.includes(props.username) && (
                            <Tooltip
                                label={closeEnough ? "You're close enough to check-in to this court!" : "You're too far away (>300m)"}
                            // isDisabled={closeEnough}
                            >
                                <Button
                                    onClick={(e) => {
                                        handleCheckIn(e, form.location)
                                    }}
                                    // w="50%"
                                    fontSize="sm"
                                    mr={2}
                                    whiteSpace="normal" // Enable text wrapping
                                    overflowWrap="break-word" // Allow words to break within the button
                                    colorScheme={closeEnough ? "teal" : "red"}
                                    isDisabled={!closeEnough}
                                >
                                    Check-In
                                </Button>
                            </Tooltip>
                        )}

                        <Button
                            onClick={(e) => {
                                copyURLToClipboard(e);
                            }}
                            whiteSpace="normal" // Enable text wrapping
                            overflowWrap="break-word" // Allow words to break within the button
                            fontSize="sm"
                        >
                            Copy Game Link
                        </Button>

                    </div>
                </form>
            </Box>
            <Spacer />

            <Box
                bg="white"
                // borderRadius="md"
                // boxShadow="md"
                // p={4}
                // h={800}
                h="100%"
                w={{ md: '40%' }}
                marginX={{ base: '0', md: '0' }}
                marginY={{ base: '0', md: '0' }}
            >
                {/* <Map selectedLocation={selectedLocation} selectedRegion={["NUS"]} /> */}
                <Tabs isFitted variant='enclosed'>
                    <TabList mb='1em'>
                        <Tab _selected={{ color: 'white', bg: 'blue.400' }} fontSize="sm">Details</Tab>
                        <Tab _selected={{ color: 'white', bg: 'blue.400' }} fontSize="sm">Map</Tab>
                        <Tab _selected={{ color: 'white', bg: 'green.400' }}
                            isDisabled={!enableMessageBoard}
                            fontSize="sm">
                            Message Board
                        </Tab>
                        <Tab _selected={{ color: 'white', bg: 'green.400' }}
                            // isDisabled={!enableMessageBoard}
                            fontSize="sm"
                        >
                            Players
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {/* Details */}
                        </TabPanel>
                        <TabPanel>
                            <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container"
                                // options={{ disableDefaultUI: true, mapTypeId: "satellite" }}>
                                mapContainerStyle={{ height: "40vh", width: "100%", borderRadius: "10px" }}
                                options={{ disableDefaultUI: disableDefaultUI, mapTypeId: mapType, styles: mapStyle }}>

                                {/* {markers} */}
                                {new_markers}
                                {myLocationMarker}
                                <Box position="absolute" bottom="2" right="2" display="flex">
                                    <Stack direction="row" spacing="2">
                                        <Tooltip label="Court location">
                                            <Button
                                                onClick={handleCourtLocation}
                                            >
                                                <GiTennisCourt />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip label="My location">
                                            <Button
                                                onClick={handleMyLocation}
                                            >
                                                <MdGpsFixed />
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                </Box>
                            </GoogleMap>

                        </TabPanel>
                        <TabPanel>
                            <Box
                                p='40px'
                                // color='white'
                                mt='4'
                                // bg='teal.500'
                                rounded='md'
                                shadow='md'
                                maxHeight='600px' // Set the maximum height of the box
                                overflow='auto' // Add a scrollbar when necessary
                            // bg="lightblue"
                            >
                                {form && form.players && form.players.includes(props.username) ? (
                                    <MessageBoard game_id={form.game_id} username={props.username} handleMessageBoardUpdate={handleMessageBoardUpdate} host={form.name} />
                                ) :
                                    <Box p="4" mt="4" rounded="md" shadow="md">
                                        <Text><h4>You have to be a registered participant to view the message board.</h4></Text>
                                    </Box>
                                }
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <PlayersTab form={form} username={props.username} updatePlayers={updatePlayers} setRefreshTrigger={setRefreshTrigger} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            </Box>
            <Spacer />
        </Flex >
    );
}