import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Stack,
    Textarea,
    Tooltip,
    useClipboard,
    useColorModeValue,
    VStack,
    List,
    ListItem,
    ListIcon,
    UnorderedList,
    Link,
    Divider,
    Image,
    Tab, TabList, TabPanel, TabPanels, Tabs
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import nadal_bg from '../images/nadal_bg.png'
import ss1 from '../images/screenshots/1.png'
import ss2 from '../images/screenshots/2.png'
import ss3 from '../images/screenshots/3.png'
import ss4 from '../images/screenshots/4.png'
import ss5 from '../images/screenshots/5.png'
import ss6 from '../images/screenshots/6.png'
import ss7 from '../images/screenshots/7.png'
import ss8 from '../images/screenshots/8.png'
import ss9 from '../images/screenshots/9.png'
import ss10 from '../images/screenshots/10.png'
import ss11 from '../images/screenshots/11.png'
import ss12 from '../images/screenshots/12.png'
import ss13 from '../images/screenshots/13.png'
import ss14 from '../images/screenshots/14.png'
import ss15 from '../images/screenshots/15.png'
import ss16 from '../images/screenshots/16.png'
import ss17 from '../images/screenshots/17.png'
import ss18 from '../images/screenshots/18.png'
import ss19 from '../images/screenshots/19.png'


export default function Instructions(props) {
    return (
        <Box p={5}>

            <Box p={2}><b>How to use SportMatchHK?</b></Box>

            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab><b>I want to Create a Game</b></Tab>
                    <Tab><b>I want to Join a Game</b></Tab>
                    <Tab><b>More Features</b></Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <p>1. To create a game, click on the "Create Game" button on the homepage.</p>
                        <Image
                            src={ss1}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>Alternatively, you can directly select the "Create Game" tab on the "Games" page.</p>
                        <Image
                            src={ss11}
                            alt="Screenshot 1"
                            // boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p>2. There are 2 ways you can create the game. Either through the map, or through the "Create a New Game" form below.
                            For the map, you will see a map of sports facilities for various sports. Click on the court that you are interested in </p>
                        <Image
                            src={ss2}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>3. You can see the details of the court in the pop-up window</p>
                        <Image
                            src={ss3}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p>4. Select "Create Game" button at the bottom of the window</p>
                        <Image
                            src={ss4}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>5. You will need to fill out the details in the "Create a Game" form below, such as location, date & time, price, max # of players allowed, skill-level, and any other comments that would help new joiners to understand what type of game it is, or also instructions on how to get there.</p>
                        <Image
                            src={ss5}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p>6. Once you have filled out the form, click on the "Create Game" button.</p>
                        <Image
                            src={ss6}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>7. Once complete, you will see the window below.</p>
                        <Image
                            src={ss7}
                            alt="Screenshot 1"
                            // boxSize="200px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>8. You should also now see your newly created game on the View Games screen.</p>
                        <Image
                            src={ss8}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p><b>Enjoy the game!💪🏽</b></p>

                    </TabPanel>
                    <TabPanel>
                        <p>1. To see all existing games, first click on the "Find Game" button on the homepage</p>
                        <Image
                            src={ss9}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>Alternatively, you can directly select the "View Games" tab on the "Games" page.</p>
                        <Image
                            src={ss11}
                            alt="Screenshot 1"
                            // boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />


                        <p>2. You will see all upcoming games in the cards below, which you can swipe to see quick details. Or click on the "View" button to see full details and see more actions.</p>
                        <Image
                            src={ss10}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p>3. See all details of the game including: Location, Date & Time, No. of Players joined, Skill level, and other comments left by the host. </p>

                        <p>4. Click on the "Join" button at the bottom to join game</p>
                        <Image
                            src={ss19}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>Note: You can only join the game as a registered user. Please click on the "Login to Join" button to login or register for an account.</p>
                        <Image
                            src={ss18}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p>Tips: Note you can filter for specific qualities by clicking the "Filter" button. You can specify the sport, timeframe, and other settings.</p>
                        <Image
                            src={ss10}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p>5. Filter for: Sport, Timeframe, and only courts with upcoming games or All Courts</p>
                        <Image
                            src={ss12}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p> Filter for specific Sport</p>
                        <Image
                            src={ss13}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />
                        <p> Show all courts regardless of whether there is an upcoming game there</p>
                        <Image
                            src={ss14}
                            alt="Screenshot 1"
                            boxSize="400px"
                            border="2px solid"
                            borderColor="gray.700"
                        />

                        <p><b>Enjoy the game!💪🏽</b></p>

                    </TabPanel>
                    <TabPanel>
                        <b><p>Additional Features:</p></b>
                        <ul>
                            <li>My Games</li>
                            <li>Profile</li>
                        </ul>
                        Stay tuned for more updates here..
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}