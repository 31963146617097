import { useState } from 'react'
import {
    Stack,
    FormControl,
    Input,
    Button,
    useColorModeValue,
    Heading,
    Text,
    Container,
    Flex,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

export default function MailingList() {
    const [email, setEmail] = useState('')
    const [state, setState] = useState('initial')
    const [error, setError] = useState(false)

    async function onSubmit(e) {
        e.preventDefault();
        // Append a name to the "players" list object inside the form state
        // console.log("form: ", form);
        // window.alert("Submit Email.")
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email || !emailRegex.test(email)) {
            window.alert("Invalid: please enter a valid email address")
            return;
        }

        // if (!email) {
        //     window.alert("Invalid: please fill in the required fields on the form")
        //     return;
        // }
        // const updatedForm = {
        //     ...form
        // };
        // { console.log("updatedForm: ", updatedForm); }

        // When a post request is sent to the create url, we'll add a new record to the database.
        const newObj = { email: email };

        // await fetch("http://localhost:5000/record/add", {
        await fetch(`${process.env.REACT_APP_API_URL}/mailing_list/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newObj),
        })
            .then(response => {
                { console.log("Response status:", response.status); }
                // if (response.status === 409) {
                //     window.alert("Court is already full at requested time. Please choose another time or court.");
                // }
                return response.json();
            })
            .then(data => {
                { console.log("Response data:", data); }
            })
            .catch(error => {
                window.alert(error);
                return;
            });
        window.alert("Joined successfully.")
        setEmail({ email: "" });
        // navigate("/");
    }

    return (
        // <Flex
        //     minH={'100vh'}
        //     align={'center'}
        //     justify={'center'}
        //     bg={useColorModeValue('gray.50', 'gray.800')}>
        <Container
            maxW={'lg'}
            bg={useColorModeValue('white', 'whiteAlpha.100')}
            boxShadow={'xl'}
            rounded={'lg'}
            p={6}>
            <Heading
                as={'h2'}
                fontSize={{ base: 'xl', sm: '2xl' }}
                textAlign={'center'}
                mb={5}
                color={'gray'}>
                Stay connected to our community!
            </Heading>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                as={'form'}
                spacing={'12px'}
                onSubmit={(e) => {
                    e.preventDefault()
                    setError(false)
                    setState('submitting')

                    // remove this code and implement your submit logic right here
                    setTimeout(() => {
                        if (email === 'fail@example.com') {
                            setError(true)
                            setState('initial')
                            return
                        }

                        setState('success')
                    }, 1000)
                }}>
                <FormControl>
                    <Input
                        variant={'solid'}
                        borderWidth={1}
                        color={'gray.800'}
                        _placeholder={{
                            color: 'gray.400',
                        }}
                        borderColor={useColorModeValue('gray.300', 'gray.700')}
                        id={'email'}
                        type={'email'}
                        required
                        placeholder={'E-mail'}
                        aria-label={'Your Email'}
                        value={email}
                        disabled={state !== 'initial'}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl w={{ base: '100%', md: '40%' }}>
                    <Button
                        colorScheme={state === 'success' ? 'green' : 'blue'}
                        isLoading={state === 'submitting'}
                        w="100%"
                        type={state === 'success' ? 'button' : 'submit'}
                        onClick={onSubmit}
                    >
                        {state === 'success' ? <CheckIcon /> : 'Submit'}
                    </Button>
                </FormControl>
            </Stack>
            <Text mt={2} textAlign={'center'} color={error ? 'red.500' : 'gray.500'}>
                {error
                    ? 'Oh no an error occured! 😢 Please try again later.'
                    // : "We promise you won't receive any spam! ✌️"}
                    : "We'll only notify you of official launch dates & prizes 🚀"}
            </Text>
        </Container>
        // </Flex>
    )
}